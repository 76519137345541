/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* Import from other files */
@import "./common/global.css";
@import "./common/header.css";
@import "./main/cases.css";
@import "./case/introduction.css";
@import "./common/contacts.css";
@import "./common/footer.css";

#not-found {
  color: #f2f2f2;
  overflow: hidden;
}

.card {
  border-radius: 20px;
  transform: rotate3d(0, 0, 1, -10deg);
  position: relative;
  display: flex;
  justify-content: space-between;
}

.card:hover {
  cursor: auto;
}

.card::before {
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="100%" height="100%" rx="20" ry="20" stroke-width="1" fill="transparent" stroke="white"/></svg>')
    0 / 100% 100%;
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="100%" height="100%" rx="20" ry="20" stroke-width="1" fill="transparent" stroke="white"/></svg>')
    0 / 100% 100%;
  z-index: inherit;
}

.card-text {
  margin-left: var(--column-width);
  margin-top: var(--column-width);
  margin-bottom: var(--column-width);
  padding: 0;
  box-sizing: border-box;
}

.card-title {
  margin-top: 20px;
  padding: 0;
  font-size: 48px;
  line-height: 1.3em;
}

.colored {
  background: linear-gradient(90deg, #a5c4e9 0%, #d8c5d6 50%, #e3a273 93.75%);
  background-clip: text;
  color: transparent;
  font-weight: normal;
  display: inline-block;
}

.card-description {
  margin-top: 20px;
  padding: 0;
}

.card a {
  align-self: flex-end;
}

.button-visit {
  width: 180px;
  height: 180px;
  position: relative;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  margin: 0 var(--column-width) var(--column-width) 0;
}

@media all and (min-width: 768px) {
  .button-visit:hover::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(
      to right,
      #a5c4e9 0%,
      #d8c5d6 44.27%,
      #e3a273 93.75%
    );
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="158px" height="158px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="158px" height="158px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
  }
}

@media all and (min-width: 1279px) {
  .button-visit:hover::after {
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="198px" height="198px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="198px" height="198px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
  }
}

@media all and (max-width: 1279px) {
  #not-found {
    padding-top: 180px;
  }

  .card-text {
    margin-left: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    --column-count: 4;
  }

  .card-title {
    font-size: 32px;
  }

  .button-visit {
    margin-right: 40px;
    margin-bottom: 40px;
    width: 140px;
    height: 140px;
  }
}

@media all and (max-width: 1023px) {
  #not-found {
    padding-top: 160px;
  }

  .card-holder {
    --column-count: 6;
  }
}

@media all and (max-width: 767px) {
  #not-found {
    padding-top: 130px;
  }

  .card-holder {
    --column-count: 3;
  }

  .card-text {
    margin-left: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    --column-count: 2;
  }

  .card-description {
    width: unset;
  }

  .button-visit {
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

@media all and (max-width: 694px) {
  .card-title {
    font-size: 24px;
    line-height: 1.5em;
  }

  .card-description {
    width: calc(var(--column-size) + var(--column-gutter) * 3);
  }
}

@media all and (max-width: 539px) {
  .card {
    display: flex;
    flex-direction: column;
  }

  .card-text {
    --column-count: 3;
    margin-right: 30px;
    width: calc(var(--column-width) * var(--column-count) + var(--column-gutter) * (var(--column-count) - 1) - 60px);
  }

  .card-description {
    width: unset;
  }
}

@media all and (max-width: 484px) {
  .card-holder {
    --column-count: 4;
  }

  .card-text {
    --column-count: 4;
  }
}
