@import "https://fonts.googleapis.com/css2?family=Poppins&display=swap";
@font-face {
  font-family: Kharkiv Tone;
  src: url("assets/fonts/Kharkiv-Tone.dfc79b6c.woff2") format("woff2");
}

:root {
  --columns-count: 12;
  --column-width: 60px;
  --column-gutter: 40px;
  --column-size: calc(var(--column-width)  + var(--column-gutter));
  --container-width: calc( var(--columns-count) * var(--column-width)  + (var(--columns-count)  - 1) * var(--column-gutter));
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  line-height: 1.5em;
}

html {
  scroll-behavior: smooth;
  min-width: 325px;
}

body.no-scroll {
  overflow-y: hidden;
}

section {
  padding: 160px 0;
}

a {
  color: inherit;
  font: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

picture {
  display: flex;
  position: relative;
}

.container {
  width: var(--container-width);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Kharkiv Tone, sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 110px;
  line-height: 1.3em;
}

h2 {
  font-size: 80px;
  line-height: 1.1em;
}

h3 {
  font-size: 64px;
  line-height: 1em;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 20px;
  line-height: 1.2em;
}

h6 {
  font-size: 18px;
  line-height: 1.2em;
}

.p14 {
  color: #70777e;
  font-size: 14px;
}

.p16 {
  font-size: 16px;
}

.button-circle {
  background: linear-gradient(to right, #a5c4e9 0%, #d8c5d6 44.27%, #e3a273 93.75%);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.button-circle p {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: normal;
}

.background-dark {
  background: url("assets/images/dark.a6a0f225.png");
}

.background-light {
  background: url("assets/images/light.1f392515.png");
}

.ml-1, .mr-1, .pl-1, .pr-1, .col-1, .wd-1 {
  --column-count: 1;
}

.ml-2, .mr-2, .pl-2, .pr-2, .col-2, .wd-2 {
  --column-count: 2;
}

.ml-3, .mr-3, .pl-3, .pr-3, .col-3, .wd-3 {
  --column-count: 3;
}

.ml-4, .mr-4, .pl-4, .pr-4, .col-4, .wd-4 {
  --column-count: 4;
}

.ml-5, .mr-5, .pl-5, .pr-5, .col-5, .wd-5 {
  --column-count: 5;
}

.ml-6, .mr-6, .pl-6, .pr-6, .col-6, .wd-6 {
  --column-count: 6;
}

.ml-7, .mr-7, .pl-7, .pr-7, .col-7, .wd-7 {
  --column-count: 7;
}

.ml-8, .mr-8, .pl-8, .pr-8, .col-8, .wd-8 {
  --column-count: 8;
}

.ml-9, .mr-9, .pl-9, .pr-9, .col-9, .wd-9 {
  --column-count: 9;
}

.ml-10, .mr-10, .pl-10, .pr-10, .col-10, .wd-10 {
  --column-count: 10;
}

.ml-11, .mr-11, .pl-11, .pr-11, .col-11, .wd-11 {
  --column-count: 11;
}

.ml-12, .mr-12, .pl-12, .pr-12, .col-12, .wd-12 {
  --column-count: 12;
}

.ml-1, .ml-2, .ml-3, .ml-4, .ml-5, .ml-6, .ml-7, .ml-8, .ml-9, .ml-10, .ml-11, .ml-12 {
  margin-left: calc(var(--column-size) * var(--column-count));
}

.mr-1, .mr-2, .mr-3, .mr-4, .mr-5, .mr-6, .mr-7, .mr-8, .mr-9, .mr-10, .mr-11, .mr-12 {
  margin-right: calc(var(--column-size) * var(--column-count));
}

.pl-1, .pl-2, .pl-3, .pl-4, .pl-5, .pl-6, .pl-7, .pl-8, .pl-9, .pl-10, .pl-11, .pl-12, .sm-pl-1, .sm-pl-2, .sm-pl-3, .sm-pl-4, .sm-pl-5, .sm-pl-6, .sm-pl-7, .sm-pl-8, .xs-pl-1, .xs-pl-2, .xs-pl-3, .xs-pl-4 {
  padding-left: calc(var(--column-size) * var(--column-count));
}

.pr-1, .pr-2, .pr-3, .pr-4, .pr-5, .pr-6, .pr-7, .pr-8, .pr-9, .pr-10, .pr-11, .pr-12 {
  padding-right: calc(var(--column-width) * var(--column-count));
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  flex-basis: 0;
  flex-grow: var(--column-count);
}

.wd-1, .wd-2, .wd-3, .wd-4, .wd-5, .wd-6, .wd-7, .wd-8, .wd-9, .wd-10, .wd-11, .wd-12, .sm-wd-1, .sm-wd-2, .sm-wd-3, .sm-wd-4, .sm-wd-5, .sm-wd-6, .sm-wd-7, .sm-wd-8, .xs-wd-1, .xs-wd-2, .xs-wd-3, .xs-wd-4 {
  width: calc( var(--column-count) * (var(--column-size))  - var(--column-gutter));
}

.col {
  flex-grow: 1;
  flex-basis: auto;
}

.flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.relative {
  position: relative;
}

img {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1920px) {
  :root {
    --column-width: 70px;
  }
}

@media (max-width: 1279px) {
  :root {
    --column-gutter: 20px;
  }

  h2 {
    font-size: 64px;
    line-height: 1.1em;
  }

  section {
    padding: 120px 0;
  }
}

@media (max-width: 1023px) {
  :root {
    --columns-count: 8;
    --column-gutter: 30px;
  }

  h2 {
    line-height: 1.3em;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 14px;
    line-height: 1.5em;
  }

  section {
    padding: 90px 0;
  }

  .sm-wd-1, .sm-pl-1 {
    --column-count: 1;
  }

  .sm-wd-2, .sm-pl-2 {
    --column-count: 2;
  }

  .sm-wd-3, .sm-pl-3 {
    --column-count: 3;
  }

  .sm-wd-4, .sm-pl-4 {
    --column-count: 4;
  }

  .sm-wd-5, .sm-pl-5 {
    --column-count: 5;
  }

  .sm-wd-6, .sm-pl-6 {
    --column-count: 6;
  }

  .sm-wd-7, .sm-pl-7 {
    --column-count: 7;
  }

  .sm-wd-8, .sm-pl-8 {
    --column-count: 8;
  }
}

@media (max-width: 767px) {
  :root {
    --columns-count: 4;
    --column-gutter: 20px;
    --column-margin: 20px;
    --column-width: calc( ( 100vw - 2 * var(--column-margin)  - (var(--columns-count)  - 1) * var(--column-gutter)) / var(--columns-count));
  }

  section {
    padding: 60px 0;
  }

  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 14px;
  }

  .xs-wd-1, .xs-pl-1 {
    --column-count: 1;
  }

  .xs-wd-2, .xs-pl-2 {
    --column-count: 2;
  }

  .xs-wd-3, .xs-pl-3 {
    --column-count: 3;
  }

  .xs-wd-4, .xs-pl-4 {
    --column-count: 4;
  }
}

header {
  --line-color: #70777e;
  --menu-color: #8b9095;
  --header-height: 80px;
  width: 100%;
  position: absolute;
}

.no-scroll-desktop header {
  width: calc(100% - 17px);
}

.light {
  --line-color: #e8e6e6;
  --menu-color: #e8e6e6;
}

header .container {
  height: var(--header-height);
  border-bottom: solid 1px var(--line-color);
  box-sizing: border-box;
  z-index: 3;
  flex-direction: row;
  position: relative;
}

.logo {
  font-family: Kharkiv Tone, sans-serif;
  font-size: 16px;
}

.active {
  color: #fff;
  position: relative;
}

.active:after, .nav-list > li:hover:after {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e3a579;
  border-radius: 50%;
  margin-left: 2px;
  position: absolute;
}

.nav-list {
  display: flex;
}

.nav-list > li {
  color: var(--menu-color);
  font-size: 14px;
  transition: color .3s;
  position: relative;
}

.nav-list > li.active, .nav-list > li:hover {
  color: #fff;
}

.nav-list > li:not(:last-child) {
  padding-right: 50px;
}

.hamburger, .overlay {
  visibility: hidden;
  display: none;
}

.hamburger {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
}

.hamburger:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 7px;
  left: 0;
}

.hamburger:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 7px;
  left: 0;
}

.hamburger.cross:before {
  background: #fff;
  transform: rotate(45deg)translate3d(3px, 150%, 0)scaleX(.7415);
}

.hamburger.cross:after {
  background: #fff;
  transform: rotate(-45deg)translate3d(3px, -150%, 0)scaleX(.7415);
}

.overlay {
  width: 100%;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  transition: visibility 0s linear .5s, opacity .5s cubic-bezier(.4, 0, .2, 1);
  position: fixed;
  top: 0;
  left: 0;
}

.overlay.visible {
  z-index: 2;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

@media (max-width: 1024px) {
  body {
    position: relative;
  }

  header nav {
    opacity: 0;
    visibility: hidden;
    left: 0;
    top: var(--header-height);
    width: 100%;
    height: calc(100vh - var(--header-height));
    justify-content: center;
    align-items: center;
    transition: visibility 0s linear .5s, opacity .5s cubic-bezier(.4, 0, .2, 1);
    display: flex;
    position: absolute;
  }

  header nav.visible {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transition-delay: 0s;
  }

  header .nav-list {
    flex-direction: column;
    align-items: center;
  }

  header .nav-list > li {
    background: linear-gradient(90deg, #a5c4e9 0%, #d8c5d6 50%, #e3a273 93.75%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    color: #0000;
    -webkit-background-clip: text;
    background-clip: text;
    font-family: Kharkiv Tone, sans-serif;
    font-size: 48px;
    font-weight: normal;
    line-height: 1.7em;
    display: inline-block;
  }

  header .nav-list > li:not(:last-child) {
    padding-right: 0;
  }

  .overlay {
    display: block;
  }

  .hamburger {
    visibility: visible;
    display: block;
  }
}

@media (max-width: 767px) {
  header nav {
    justify-content: flex-end;
    align-items: flex-end;
  }

  header .nav-list {
    align-items: flex-end;
    margin-bottom: 105px;
  }

  header .nav-list > li {
    font-size: 32px;
    line-height: 1.6em;
  }
}

#cases h4 {
  color: #fff;
  padding-bottom: 60px;
}

.cases-list {
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 60px;
  display: flex;
}

.cases-list li {
  width: calc((4 * var(--column-size))  - var(--column-gutter));
  padding-top: 47.414%;
  position: relative;
}

.card, .more-cases {
  border-radius: 4px;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.card:before {
  content: "";
  z-index: 1;
  background: linear-gradient(to right, #a5c4e9, #d8c5d6, #e3a273);
  position: absolute;
  inset: 0;
  -webkit-mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"0\" y=\"0\" width=\"100%\" height=\"100%\" rx=\"4\" ry=\"4\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
  mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"0\" y=\"0\" width=\"100%\" height=\"100%\" rx=\"4\" ry=\"4\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
}

.card-text {
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.card-date {
  color: #70777e;
  padding-left: 12px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2em;
  display: inline-block;
  position: relative;
}

.card-date:before {
  content: "";
  width: 4px;
  height: 4px;
  background: #afc5e6;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
}

.card-title {
  color: #f2f2f2;
  padding-top: 8px;
}

.card-description {
  color: #70777e;
  padding-top: 16px;
}

.card-image {
  width: 77.778%;
  height: 65.455%;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  right: 0;
}

.card:hover {
  cursor: pointer;
}

.card:hover .card-image {
  width: 100%;
  height: 100%;
}

.card-image img {
  height: 100%;
}

.more-cases {
  justify-content: center;
  align-items: center;
  display: flex;
}

.more-cases img {
  width: auto;
}

.more-cases > img {
  height: 180px;
}

.more-cases div {
  justify-content: space-between;
  align-items: center;
  column-gap: 70px;
  margin-top: 4px;
  margin-left: -10px;
  display: flex;
  position: absolute;
}

.more-cases p {
  color: #f2f2f2;
}

@media (min-width: 1920px) {
  .cases-list li {
    padding-top: 47.6563%;
  }

  .card-image {
    width: 75%;
    height: 68.8525%;
  }
}

@media (max-width: 1279px) {
  .cases-list {
    row-gap: 40px;
  }

  .cases-list li {
    padding-top: 48.9362%;
  }

  .card-image {
    width: 70%;
    height: 58.696%;
  }

  .more-cases > div {
    column-gap: 30px;
    margin-left: 0;
  }

  .more-cases > div > img {
    width: 50px;
  }

  .more-cases p {
    font-size: 14px;
  }

  .more-cases > img {
    height: 120px;
  }

  .card-text {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1023px) {
  .cases-list {
    row-gap: var(--column-gutter);
  }

  .cases-list li {
    padding-top: 72.464%;
  }

  .cases-list li:last-child {
    padding-top: 0;
  }

  #cases h4 {
    padding-bottom: 40px;
  }

  .card-image {
    width: 75.758%;
    height: 64%;
  }

  .cases-list > li:last-child {
    flex-grow: 2;
  }

  .more-cases {
    height: auto;
    width: 100%;
    margin-top: 30px;
    position: relative;
  }
}

@media (max-width: 767px) {
  #cases h4 {
    padding-bottom: 30px;
  }

  .cases-list li {
    padding-top: 142.857%;
  }

  .card-text {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .card-description {
    padding-top: 8px;
  }

  .card-image {
    width: 67.8572%;
    height: 61.25%;
  }
}

#introduction {
  color: #626262;
  padding: 120px 0;
}

#introduction .container {
  position: relative;
}

.intro-header {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;
  display: flex;
}

.intro-header p {
  font-size: 14px;
}

#introduction img {
  border-radius: 20px;
}

#introduction picture:before {
  content: "";
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 6px solid #626f7d;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.button-visit {
  width: 140px;
  height: 140px;
  animation: 5s linear infinite rotating;
  bottom: 60px;
  right: 60px;
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .button-visit:hover:after {
    content: "";
    z-index: 1;
    background: linear-gradient(to right, #a5c4e9 0%, #d8c5d6 44.27%, #e3a273 93.75%);
    position: absolute;
    inset: -10px;
    -webkit-mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"158px\" height=\"158px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
    mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"158px\" height=\"158px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
  }
}

@media (max-width: 1439px) {
  #introduction {
    padding: 100px 0;
  }
}

@media (max-width: 1279px) {
  #introduction {
    padding: 90px 0;
  }
}

@media (max-width: 1023px) {
  #introduction {
    padding: 60px 0;
  }

  #introduction picture:before {
    border-width: 4px;
  }

  .intro-header {
    margin-bottom: 20px;
  }

  .button-visit {
    width: 100px;
    height: 100px;
    bottom: 30px;
    right: 30px;
  }

  .button-visit:hover:after {
    inset: -5px;
    -webkit-mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"108px\" height=\"108px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
    mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"108px\" height=\"108px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
  }
}

@media (max-width: 767px) {
  #introduction {
    padding: 40px 0;
  }

  #introduction picture:before {
    border-width: 2px;
    border-radius: 10px;
  }

  #introduction img {
    border-radius: 10px;
  }

  .intro-header {
    margin-bottom: 10px;
  }

  .button-visit {
    bottom: 20px;
    right: 20px;
  }
}

#contacts .container {
  align-items: start;
}

#contacts h4 {
  padding-bottom: 30px;
}

#contacts h2 {
  max-width: 660px;
  padding-bottom: 64px;
}

.contacts {
  justify-content: start;
  align-items: center;
  display: flex;
}

.contact {
  justify-content: start;
  align-items: center;
  margin-right: 80px;
  display: flex;
}

.contact-image {
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  display: flex;
  position: relative;
}

.contact-image img {
  width: auto;
}

.contact-image .contact-image-bg {
  width: 100%;
}

.contact-image-bg {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1279px) {
  #contacts h2 {
    max-width: 560px;
  }
}

@media (max-width: 1023px) {
  .contact {
    margin-right: 60px;
  }

  #contacts h2 {
    font-size: 64px;
    line-height: 1.3em;
  }

  #contacts h2, #contacts h4 {
    padding-bottom: 20px;
  }

  #contacts p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  #contacts .container {
    align-items: center;
  }

  #contacts h2 {
    text-align: center;
    padding-bottom: 30px;
    font-size: 48px;
    line-height: 1.3em;
  }

  #contacts h4 {
    padding-bottom: 20px;
  }

  .contacts {
    flex-direction: column;
    align-self: flex-end;
    align-items: flex-end;
    gap: 20px;
  }

  .contact {
    margin-right: 0;
  }
}

footer {
  color: #2c2c2c;
}

footer .container {
  box-sizing: border-box;
  border-top: 1px solid #2c2c2c;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 75px;
  position: relative;
}

footer .socials {
  display: flex;
}

footer .socials > * {
  margin-left: 50px;
}

@media (max-width: 1279px) {
  footer .container {
    padding-bottom: 50px;
  }
}

@media (max-width: 1023px) {
  footer {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  footer .container {
    height: auto;
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 22px;
  }

  footer .socials {
    width: 100%;
    justify-content: space-between;
  }

  footer .socials > * {
    margin-left: 0;
  }
}

#not-found {
  color: #f2f2f2;
  overflow: hidden;
}

.card {
  border-radius: 20px;
  justify-content: space-between;
  display: flex;
  position: relative;
  transform: rotate3d(0, 0, 1, -10deg);
}

.card:hover {
  cursor: auto;
}

.card:before {
  z-index: inherit;
  -webkit-mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"0\" y=\"0\" width=\"100%\" height=\"100%\" rx=\"20\" ry=\"20\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
  mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"0\" y=\"0\" width=\"100%\" height=\"100%\" rx=\"20\" ry=\"20\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
}

.card-text {
  margin-left: var(--column-width);
  margin-top: var(--column-width);
  margin-bottom: var(--column-width);
  box-sizing: border-box;
  padding: 0;
}

.card-title {
  margin-top: 20px;
  padding: 0;
  font-size: 48px;
  line-height: 1.3em;
}

.colored {
  color: #0000;
  background: linear-gradient(90deg, #a5c4e9 0%, #d8c5d6 50%, #e3a273 93.75%);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: normal;
  display: inline-block;
}

.card-description {
  margin-top: 20px;
  padding: 0;
}

.card a {
  align-self: flex-end;
}

.button-visit {
  width: 180px;
  height: 180px;
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  margin: 0 var(--column-width) var(--column-width) 0;
  position: relative;
}

@media (min-width: 768px) {
  .button-visit:hover:after {
    content: "";
    z-index: 1;
    background: linear-gradient(to right, #a5c4e9 0%, #d8c5d6 44.27%, #e3a273 93.75%);
    position: absolute;
    inset: -10px;
    -webkit-mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"158px\" height=\"158px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
    mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"158px\" height=\"158px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
  }
}

@media (min-width: 1279px) {
  .button-visit:hover:after {
    -webkit-mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"198px\" height=\"198px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
    mask: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\"><rect x=\"1\" y=\"1\" width=\"198px\" height=\"198px\" rx=\"50%\" ry=\"50%\" stroke-width=\"1\" fill=\"transparent\" stroke=\"white\"/></svg>") 0 / 100% 100%;
  }
}

@media (max-width: 1279px) {
  #not-found {
    padding-top: 180px;
  }

  .card-text {
    --column-count: 4;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 40px;
  }

  .card-title {
    font-size: 32px;
  }

  .button-visit {
    width: 140px;
    height: 140px;
    margin-bottom: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 1023px) {
  #not-found {
    padding-top: 160px;
  }

  .card-holder {
    --column-count: 6;
  }
}

@media (max-width: 767px) {
  #not-found {
    padding-top: 130px;
  }

  .card-holder {
    --column-count: 3;
  }

  .card-text {
    --column-count: 2;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  .card-description {
    width: unset;
  }

  .button-visit {
    margin-bottom: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 694px) {
  .card-title {
    font-size: 24px;
    line-height: 1.5em;
  }

  .card-description {
    width: calc(var(--column-size)  + var(--column-gutter) * 3);
  }
}

@media (max-width: 539px) {
  .card {
    flex-direction: column;
    display: flex;
  }

  .card-text {
    --column-count: 3;
    width: calc(var(--column-width) * var(--column-count)  + var(--column-gutter) * (var(--column-count)  - 1)  - 60px);
    margin-right: 30px;
  }

  .card-description {
    width: unset;
  }
}

@media (max-width: 484px) {
  .card-holder, .card-text {
    --column-count: 4;
  }
}

/*# sourceMappingURL=404.72e9ce37.css.map */
