#introduction {
  padding: 120px 0;
  color: #626262;
}

#introduction .container {
  position: relative;
}

.intro-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.intro-header p {
  font-size: 14px;
}

#introduction img {
  border-radius: 20px;
}

#introduction picture::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 6px solid #626f7d;
  border-radius: 20px;
  box-sizing: border-box;
}

.button-visit {
  width: 140px;
  height: 140px;
  bottom: 60px;
  right: 60px;
  animation: rotating 5s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media all and (min-width: 768px) {
  .button-visit:hover::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(
      to right,
      #a5c4e9 0%,
      #d8c5d6 44.27%,
      #e3a273 93.75%
    );
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="158px" height="158px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="158px" height="158px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
  }
}

@media all and (max-width: 1439px) {
  #introduction {
    padding: 100px 0;
  }
}

@media all and (max-width: 1279px) {
  #introduction {
    padding: 90px 0;
  }
}

@media all and (max-width: 1023px) {
  #introduction {
    padding: 60px 0;
  }

  #introduction picture::before {
    border-width: 4px;
  }

  .intro-header {
    margin-bottom: 20px;
  }

  .button-visit {
    width: 100px;
    height: 100px;
    bottom: 30px;
    right: 30px;
  }

  .button-visit:hover::after {
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="108px" height="108px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="108px" height="108px" rx="50%" ry="50%" stroke-width="1" fill="transparent" stroke="white"/></svg>')
      0 / 100% 100%;
  }
}

@media all and (max-width: 767px) {
  #introduction {
    padding: 40px 0;
  }

  #introduction picture::before {
    border-width: 2px;
    border-radius: 10px;
  }

  #introduction img {
    border-radius: 10px;
  }

  .intro-header {
    margin-bottom: 10px;
  }

  .button-visit {
    bottom: 20px;
    right: 20px;
  }
}
